@import '@styles/colors.scss';
@import '@styles/fonts.scss';

.pin-and-pair-container {
  color: var(--textColor);
  min-height: 100vh;
  position: relative;

  .header {
    justify-content: flex-end;
  }

  .pin-and-pair-content {
    display: flex;
    flex-direction: column;
    padding: 99px 110px 0 110px;

    .pin-and-pair-title {
      font-weight: $font-weight-bold;
      font-size: $font-size-xxl;
    }

    .steps {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .qr-code {
      width: 40%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 50px;

      .qr-container {
        background-color: var(--textColor);
        width: 380px;
        height: 380px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          height: 330px;
          width: 330px;
        }
      }
    }

    .pin-and-pair-steps-container {
      padding-top: 102px;
      width: 60%;

      .pin-and-pair-step {
        display: flex;
        align-items: top;
        padding-bottom: 46px;

        .pin-and-pair-step-badge {
          border: 3px solid var(--secondary);
          border-radius: 43px;
          padding: 6px 30px;
          margin-right: 66px;
          font-size: $font-size-l;
          font-weight: $font-weight-regular;
          height: 58px;
        }

        .pin-and-pair-step-info {
          .pin-and-pair-step-label {
            max-width: 520px;
            font-size: $font-size-l;
            color: rgba(var(--textColor-rgb), 0.6);
          }

          .pin-and-pair-step-value {
            font-size: $font-size-xxl;
            font-weight: $font-weight-bold;
          }

          .url {
            font-size: $font-size-l;
          }

          .code {
            font-size: 60px;
          }
        }
      }
    }

    .login-with-device-info {
      display: flex;
      align-items: center;
      align-self: center;
      justify-content: center;
      margin-top: 21px;

      .login-with-device-label {
        padding-left: 22px;
        font-size: $font-size-l;
        font-weight: $font-weight-bold;
      }
    }

    .button-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      transform: translate(-50%, 0);
      left: 50%;
      bottom: 48px;

      p {
        font-size: $font-size-m;
        line-height: 35px;
        margin-bottom: 35px;
      }

      .secondary-button {
        width: 408px;
      }
    }
  }

  .pin-and-pair-overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(var(--primary-rgb), 0.8);
    z-index: 9999;
  }

  .code-expired {
    width: 737px;
    height: 367px;
    background: var(--secondary);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;

    &-message {
      color: var(--textColor);
      font-size: $font-size-l;
      font-weight: $font-weight-medium;
      padding-bottom: 16px;
    }

    &-instructions {
      color: rgba(var(--textColor-rgb),0.6);
      font-size: $font-size-m;
      font-weight: $font-weight-regular;
      padding-bottom: 68px;
    }
  }
}