@import '@styles/colors.scss';

.dot-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dot-spinner-search {
  height: 150px;
  width: 90%;
  text-align: center;
  margin-bottom: 100px;

  img {
    height: 100px;
    width: 100px;
  }
}

.dot-spinner,
.dot-spinner-search {
  .image-wrapper.poster {
    background-color: transparent;
  }
}
