@import '@styles/colors.scss';
@import '@styles/variables.scss';
@import '@styles/fonts.scss';

.primary-player-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius-circle;
  border: none;
  outline: none;

  path {
    fill: var(--textColor);
  }
}

.back-button {
  width: 88px;
  height: 88px;
  background-color: transparent;

  color: var(--textColor);

  svg {
    fill: var(--textColor);
    width: 40px;
    height: 40px;

    path {
      opacity: 1;
    }
  }

  &:focus,
  &:hover {
    background-color: var(--neutral);

    svg {
      fill: var(--primary);

      path {
        fill: var(--primary);
      }
    }
  }
}

.play-button {
  width: 78px;
  height: 78px;
  background-color: rgba(var(--neutral-rgb), 0.3);

  svg {
    width: 40px;
    height: 40px;

    path {
      opacity: 1;
    }

    rect {
      stroke: var(--textColor);
      fill: var(--textColor);
    }
  }

  .play-svg {
    margin-left: 8px;

    path {
      stroke: var(--textColor);
    }
  }

  &:focus,
  &:hover {
    background-color: var(--neutral);

    svg {
      fill: var(--primary);

      path {
        fill: var(--primary);
        stroke: var(--primary);
      }

      rect {
        stroke: var(--primary);
        fill: var(--primary);
      }
    }
  }

  &:focus {
    width: 88px;
    height: 88px;
  }
}
