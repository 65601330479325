.synopsis-primary-skeleton-wrapper {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 60px;

  .skeleton-wrapper-left,
  .skeleton-wrapper-middle,
  .skeleton-wrapper-right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .skeleton-wrapper-left,
  .skeleton-wrapper-middle {
    width: 345px;
  }

  .details-content,
  .details-content-short,
  .details-content-big-1,
  .details-content-big-2,
  .details-content-big-3 {
    height: 35px;
    border-radius: 23px;
    margin-bottom: 15px;
  }

  .details-content {
    width: 202px;
  }

  .details-content-short {
    width: 134px;
  }

  .details-content-big-1 {
    width: 527px;
  }

  .details-content-big-2 {
    width: 842px;
  }

  .details-content-big-3 {
    width: 1000px;
  }
}
