@import '@styles/colors.scss';
@import '@styles/fonts.scss';

.account-error {
  width: 1920px;
  height: calc(1080px - 126px);

  &__text {
    font-size: $font-size-xxl;
    font-weight: $font-weight-bold;
    line-height: 52px;
    color: var(--textColor);
    margin: 319px 0 0 120px;
  }

  &__button-container {
    margin: 32px 0 0 120px;

    .secondary-button {
      &:nth-child(2) {
        margin-left: 20px;
      }
    }
  }
}
