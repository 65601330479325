@import '@styles/colors.scss';
@import '@styles/variables.scss';
@import '@styles/fonts.scss';

.hcat-type {
  width: $categoryCardWidth;
  height: $categoryCardHeight;
  font-weight: $font-weight-regular;
  font-size: $font-size-m;
  background-color: var(--background);
  border: $border-width-thick solid rgba(var(--textColor-rgb), 0.1);
  box-shadow: 0px 1.6px 1.6px 0px $boxShadow;
  color: rgba(var(--textColor-rgb), 0.9);
  border-radius: $border-radius-base;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;

  &__is_active,
  &__is_active:hover {
    border: $border-width-thick solid var(--textColor);
    transform: scale(1.09);
    color: var(--textColor);
  }
}
