@import '@styles/colors.scss';
@import '@styles/fonts.scss';

.profile-info-container {
  color: var(--textColor);
  margin: 196px 0 0 56px;

  .profile-title {
    margin-left: 20px;
  }

  .profile-details {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .displayName {
      text-transform: capitalize;
    }

    .profile-details-item {
      width: 660px;
      margin-right: 64px;

      &:not(:first-of-type) {
        margin-top: 26px;
      }

      .profile-details-item-label {
        font-size: $font-size-m;
        line-height: 34px;
        margin-bottom: 8px;
      }

      .profile-details-item-value {
        font-size: $font-size-xl;
        font-weight: $font-weight-bold;
        line-height: 40px;
        text-transform: capitalize;
      }
    }

    .profile-image {
      width: 990px;
      display: flex;
      justify-content: center;

      .profile-image-letter {
        font-size: 80px;
        font-weight: $font-weight-bold;
        color: $profileImgLetter;
        width: 284px;
        height: 284px;
        border-radius: 24px;
        background-image: $profileImgBackground;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        img {
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;

          border-radius: 24px;
        }
      }
    }
  }

  .profile-edit-hint {
    font-size: $font-size-m;
    color: rgba(var(--textColor-rgb), 0.6);
    margin-top: 126px;
  }
}
