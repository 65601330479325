@import '@styles/colors.scss';
@import '@styles/variables.scss';
@import '@styles/fonts.scss';
@import '@styles/mixins.scss';

.hcw-type {
  width: 320px;
  height: 185px;
  border-radius: $border-radius-base;
  background-color: var(--secondary);

  &__progress,
  &__progress--focus {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 5px;
    background-color: rgba(var(--textColor-rgb), 0.5);
    z-index: 3;

    &--active {
      width: 100%;
      height: 100%;
      background-color: var(--info);
    }
  }

  &__progress--focus {
    width: 92.5%;
    height: 5px;
    margin: 0 12px;
    bottom: 12px;
    border-radius: 18px;
    overflow-x: hidden;
  }

  &__focus {
    background-color: rgba(var(--primary-rgb), 0.7);
    opacity: 0;

    &--wrapper {
      color: var(--textColor);
      font-weight: $font-weight-bold;
      margin: 8px 12px;

      &--title,
      &--subtitle {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--textColor);
      }

      &--title {
        font-size: $font-size-m;
        line-height: 32px;
        margin-bottom: 5px;
      }

      &--subtitle {
        font-size: $font-size-s;
        line-height: 32px;
      }
    }
  }

  &__focused {
    opacity: 1;
  }

  &__container {
    width: 320px;
    height: 100%;
    align-items: flex-start;
    position: relative;
    overflow: hidden;
    border-radius: 8px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}
