@import '@styles/colors.scss';
@import '@styles/variables.scss';
@import '@styles/fonts.scss';
@import '@styles/mixins.scss';

.open-side-menu-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  background: $openSideMenuBackground;
  top: 0;
  left: 0;

  .open-side-menu {
    height: 100vh;
    width: 300px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &-user {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 46px 0 215px 0px;

      .open-side-menu-profile {
        display: flex;
        align-items: center;
        margin-left: 35px;
        opacity: 1;
        text-decoration: none;
        min-width: 150px;

        &.active {
          .open-side-menu-profile-image {
            opacity: 1;
            border: $border-width-medium solid var(--neutral);
            border-radius: 8px;
          }
        }

        &-image {
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: 58px;
          height: 58px;
          background-image: $profileImgBackground;
          border-radius: 6px;
          opacity: 0.7;

          img {
            border-radius: 6px;
          }

          &-letter {
            font-size: $font-size-l;
            font-weight: $font-weight-bold;
            color: $profileImgLetter;
          }
        }

        &-name {
          font-size: $font-size-m;
          color: rgba(var(--textColor-rgb), 0.6);
          margin-left: 15px;
          text-decoration: none;
          text-transform: capitalize;
          word-break: break-word;
          @include clamp-text(1);
        }

        &:focus {
          outline: none;
          transition: all 0.3s ease;

          .open-side-menu-profile-image {
            opacity: 1;
          }

          .open-side-menu-profile-name {
            transition: all 0.3s ease;
            color: var(--textColor);
          }
        }

        &:hover {
          transform: scale(1.05);
          transition: all 0.3s ease;

          .open-side-menu-profile-name {
            color: var(--textColor);
          }
        }
      }
    }

    a {
      &:hover {
        transform: scale(1.05);
        transition: font-weight 0.3s ease;
        color: var(--textColor);
      }

      svg {
        width: 32px;
        height: 32px;
        flex-shrink: 0;
      }
    }
  }

  .open-side-menu-navigation {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    font-size: $font-size-m;

    a {
      height: 42px;
      padding-left: 48px;
      display: flex;
      text-decoration: none;
      margin-bottom: 42px;

      &:focus {
        outline: none;

        .open-side-menu-wrapper .open-side-menu-item {
          color: var(--textColor);
          transition: color 0.3s ease;

          svg {
            opacity: 1;
            transition: opacity 0.3s ease;
          }
        }
      }

      &.active {
        position: relative;

        .open-side-menu-wrapper .open-side-menu-item {
          svg {
            opacity: 1;
            min-width: 32px;
          }
        }
      }

      &:hover {
        outline: none;
        scale: 1.04;
        transition: all 0.4s ease;

        .open-side-menu-wrapper .open-side-menu-item {
          color: var(--textColor);
          transition: color 0.3s ease;

          svg {
            opacity: 1;
            transition: opacity 0.3s ease;
          }
        }
      }

      &.active .side-menu-border {
        width: 100%;
        position: absolute;
        bottom: -8px;
        left: 48px;
        display: block;
        margin-top: 4px;
        margin-right: auto;
        border-bottom: 4px solid var(--info);
        border-radius: 15px;
        width: 32px;
      }

      .open-side-menu-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        flex-direction: column;

        .open-side-menu-item {
          display: flex;
          align-items: center;
          color: rgba(var(--textColor-rgb), 0.6);

          svg {
            opacity: 0.6;
            min-width: 32px;

            path {
              stroke: var(--textColor);
            }

            .fill {
              fill: var(--textColor);
            }
          }

          .open-side-menu-text {
            font-size: $font-size-m;
            padding-right: 24px;
            margin-left: 15px;
            font-style: normal;
            @include clamp-text(1);
          }
        }
      }
    }

    :last-child {
      margin-bottom: 0px;
    }
  }
}
