.season-tabs-skeleton-container {
  margin-top: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .season-tab-skeleton {
    width: 150px;
    height: 20px;
    border-radius: 23px;
    margin-right: 20px;
  }
}
