@import '@styles/colors.scss';
@import '@styles/variables.scss';
@import '@styles/fonts.scss';

.overlay-wrapper {
  position: fixed;
  top: 0;
  bottom: 209px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: transparent;
  padding-left: 120px;

  &-top-section,
  &-bottom-section,
  &-center {
    display: flex;
    height: 100%;
    width: 100%;
  }

  .top-left {
    .back-button {
      margin: 50px 0 0 47px;
    }
  }

  .bottom-left {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;

    .movie-info {
      font-weight: $font-weight-medium;
      margin-bottom: 43px;

      .title {
        font-size: $font-size-xl;
        color: var(--textColor);
      }

      .description {
        font-size: $font-size-l;
        color: rgba(var(--textColor-rgb), 0.6);
      }

      .title,
      .description {
        padding-right: 120px;
      }
    }

    .buttons {
      display: flex;
    }

    .secondary-player-button {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-right: 8px;
        stroke: var(--textColor);
      }

      &:focus,
      &:hover {
        svg {
          stroke: var(--primary);
        }
      }
    }

    .secondary-player-button {
      margin-right: 32px;
    }
  }
}
