@import '@styles/colors.scss';
@import '@styles/fonts.scss';

.secondary-button {
  background-color: rgba(var(--textColor-rgb), 0.55);
  width: 320px;
  height: 54px;
  outline: none;
  border: none;
  border-radius: 41px;
  font-size: $font-size-m;
  font-weight: $font-weight-regular;
  color: var(--textColor);

  &:focus,
  &:focus:hover {
    background-color: var(--neutral);
    font-weight: $font-weight-medium;
    color: var(--primary);
  }

  &:hover {
    background-color: rgba(var(--textColor-rgb), 0.1);
    color: var(--neutral);
  }
}
