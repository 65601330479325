.homepage-skeleton-wrapper {
  padding-left: 120px;

  .skeleton-section-title {
    width: 150px;
    height: 34px;
    border-radius: 48px;
    margin: 100px 0 24px 0;
  }
}
