@import '@styles/colors.scss';
@import '@styles/fonts.scss';

.profile-button {
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: $font-size-m;
  padding: 16px 32px;

  &.small {
    width: 437px;
  }

  &.medium {
    width: 660px;
  }

  &.large {
    width: 895px;
  }

  svg {
    stroke: var(--textColor);

    path {
      fill: var(--textColor);
    }
  }

  .innerText {
    display: flex;
    justify-content: space-between;
    width: 95%;
  }

  &:focus {
    outline: none;
    background-color: var(--neutral);
    color: var(--primary);

    svg {
      path {
        fill: $standardBlack;
        stroke: $standardBlack;
      }
    }
  }

  &:focus:hover {
    outline: none;
    background-color: var(--neutral);
    color: var(--primary);
  }

  &:hover {
    outline: none;
    background-color: rgba(var(--secondary-rgb), 0.9);
    color: var(--textColor);
  }
}

.profile-button.active {
  outline: none;
  background-color: var(--neutral);
  color: var(--primary);

  svg {
    path {
      fill: $standardBlack;
      stroke: $standardBlack;
    }
  }
}
