@import '@styles/colors.scss';
@import '@styles/variables.scss';
@import '@styles/fonts.scss';

.player-controls-progress-bar {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 47px;

  .playback-time {
    width: 105px;
    color: var(--textColor);
    font-size: $font-size-m;
    font-weight: $font-weight-regular;
    height: 34px;
  }

  .live-indicator {
    color: var(--danger);
    font-size: $font-size-xxl;
    font-weight: $font-weight-bold;
    width: 120px;
    display: flex;
    align-items: center;
    gap: 8px;

    &-text {
      font-size: $font-size-s;
      color: var(--textColor);
    }
  }

  &-container {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    margin: 0 34px;

    .tooltip {
      color: var(--textColor);
      background-color: rgba(var(--textColor-rgb), 0.06);
      display: flex;
      padding: 6px 8px;
      position: absolute;
      bottom: 34px;
      border-radius: 6px;

      span {
        align-items: center;
        display: flex;
        justify-content: center;
        height: 23px;
        font-size: $font-size-xs;
        font-weight: $font-weight-bold;
      }
    }

    .progress-bar {
      width: 100%;
      display: flex;
      background-color: rgba(var(--textColor-rgb), 0.6);
      height: 10px;
      overflow: hidden;
      border-radius: $border-radius-base;

      .progress-indicator {
        position: absolute;
        top: -2px;

        .indicator-dot {
          height: 14px;
          width: 14px;
          background-color: var(--textColor);
          border-radius: $border-radius-circle;
        }

        .indicator-line {
          height: 34px;
          width: 4px;
          background-color: var(--textColor);
          position: absolute;
          top: -9.8px;
          border-radius: 5px;
        }
      }

      progress[value] {
        height: 100%;
        width: 100%;
        border-radius: $border-radius-base;
      }

      progress[value]:focus {
        border: none;
        outline: none;
      }

      progress[value]::-webkit-progress-bar {
        background-color: rgba(var(--textColor-rgb), 0.6);
      }

      progress[value]::-webkit-progress-value {
        background-color: var(--info);
        border-radius: $border-radius-base;
      }
    }
  }
}
