@import '@styles/colors.scss';
@import '@styles/fonts.scss';

.my-list {
  padding-left: 142px;

  &-title {
    font-size: $font-size-m;
    color: var(--textColor);
    font-weight: $font-weight-medium;
    margin: 92px 0 22px 0px;
  }

  .card-wrapper {
    display: flex;
    flex-wrap: wrap;

    > div {
      width: 320px;
      margin: 0 20px 40px 0;
    }
  }
}
