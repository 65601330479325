@import '@styles/colors.scss';
@import '@styles/fonts.scss';

.login {
  background: var(--background);
  width: 100%;
  height: 100vh;
  font-family: var(--font-family-sans);
  color: rgba(var(--textColor-rgb), 0.8);

  &__email-input,
  &__password-input {
    margin-top: 36px;
  }

  &__password-input {
    .secondary-button:nth-child(2) {
      margin-top: 24px;
    }
  }

  &__wrapper {
    display: flex;
    width: 35%;
    margin: 0 auto;

    .showPassword {
      font-size: $font-size-s;
      position: absolute;
      top: 262px;
      right: 420px;

      &:hover {
        color: var(--textColor);
      }
    }
  }

  &__button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 56px;

    p {
      font-weight: $font-weight-regular;
      font-size: $font-size-m;
      line-height: 120%;
      margin: 40px 0 32px 0;
    }
  }
}
