/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@300;400;500;700&display=swap');

:root {
  /* Font families */
  --font-family-sans: 'DM Sans', sans-serif;
}

//Font size
$font-size-xs: 16px;
$font-size-s: 21px;
$font-size-m: 26px;
$font-size-l: 30px;
$font-size-xl: 33px;
$font-size-xxl: 40px;

// Font weight
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
 