@import '@styles/fonts';

* {
  box-sizing: border-box;
  margin: 0;
  font-family: 'DM Sans', sans-serif;
}

body {
  margin: 0;
  font-family: 'DM Sans', sans-serif;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--primary);
}

body::-webkit-scrollbar {
  display: none;
}

.app-content {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
}

.app-side-menu {
  position: fixed;
  z-index: 1000;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
