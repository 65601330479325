@import '@styles/colors.scss';
@import '@styles/variables.scss';

.vs-type {
  margin: 18px 0;
  width: 320px;
  height: 402px;
  border-radius: $border-radius-base;
  background-color: var(--secondary);
  border-radius: $border-radius-base;

  .card-image-wrapper {
    img {
      object-fit: fill;
    }
  }

  .fallback-image {
    img {
      object-fit: cover;
    }
  }
}
