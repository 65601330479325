@import '@styles/colors.scss';
@import '@styles/variables.scss';
@import '@styles/fonts.scss';
@import '@styles/mixins.scss';

.hc-type {
  width: 320px;
  max-height: 248px;

  .img-placeholder {
    width: 320px;
    min-height: 185px;
    background-color: var(--secondary);
    position: absolute;
    border-radius: $border-radius-base;
  }

  &__top-container {
    width: 320px;
    height: 185px;
    border-radius: $border-radius-base;
    align-items: flex-start;
    position: relative;

    img {
      border-radius: $border-radius-base;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    .tags-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      display: flex;
      overflow: hidden;
      z-index: 2;
      padding-top: 16px;

      .single-tag {
        font-size: $font-size-s;
        font-weight: $font-weight-regular;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: center;
        background-color: rgba(var(--neutral-rgb), 0.6);
        color: $standardBlack;
        padding: 0 18px;
        margin-left: 10px;
        border-radius: 71px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .duration-container {
      font-size: $font-size-xs;
      font-weight: $font-weight-regular;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: center;
      position: absolute;
      right: 14px;
      bottom: 14px;
      color: var(--textColor);
      background-color: $velvetGrayOpacity;
      width: 68px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5%;
    }

    .duration-container__is_active,
    .tags-container__is_active {
      transform: scale(1.09);
      opacity: 1;
    }
  }

  &__bottom-container {
    font-size: $font-size-s;
    font-weight: $font-weight-regular;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    padding: 20px 1px;

    p {
      color: var(--textColor);
      overflow: hidden;
      max-width: 100%;
      text-overflow: ellipsis;
    }

    p {
      &:nth-child(2) {
        display: none;
      }
    }
  }

  &__bottom-container-two-lines {
    p {
      @include clamp-text(2);
    }
  }

  &__is_active {
    .tags-container {
      .single-tag {
        background-color: var(--neutral);
      }
    }
  }
}
