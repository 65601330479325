@import '@styles/colors.scss';
@import '@styles/fonts.scss';
@import '@styles/mixins.scss';

.ec-type {
  width: 300px;
  height: 350px;
  position: relative;
  opacity: 0.8;

  img {
    width: 100%;
    height: 100%;
  }

  .ec-type-top {
    width: 300px;
    height: 167px;
    border-radius: 8px;

    .episode-season {
      font-size: $font-size-xs;
      font-weight: $font-weight-bold;
      position: absolute;
      top: 126px;
      left: 6px;
      display: none;
    }
  }

  .ec-type-bottom {
    position: absolute;
    top: 193px;

    .episode-name {
      font-size: $font-size-s;
      font-weight: $font-weight-bold;
      margin-bottom: 10px;
      opacity: 0.8;
    }

    .episode-description,
    .episode-length {
      font-size: $font-size-xs;
      line-height: 21px;
    }

    .episode-length {
      color: rgba(var(--textColor-rgb), 0.5);
    }

    .episode-description {
      margin-bottom: 14px;
      opacity: 0.6;
      white-space: pre-wrap;
      text-overflow: ellipsis;
      max-height: 105px;
      @include clamp-text(5);
    }
  }
}
