@import '@styles/colors.scss';

.menu {
  height: 100vh;
  width: 120px;
  display: none;
}

.menu.visible {
  display: block;
}
