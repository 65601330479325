@import '@styles/fonts';
@import '@styles/variables';
@import '@styles/colors';

.modal {
  background-color: $playerModalBackground;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  .back-button {
    position: absolute;
    top: 50px;
    left: 167px;
  }

  .inner {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px 80px;
    color: var(--textColor);

    .title {
      font-size: $font-size-xxl;
      font-weight: $font-weight-bold;
      margin-left: 10px;
    }

    .subtitles-part,
    .audio-part {
      padding-top: 208px;
    }

    .subtitle-item,
    .audio-item {
      font-size: $font-size-m;
      background-color: transparent;
      color: var(--textColor);
      border: none;
      width: 490px;
      height: 72px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .innerText {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        path {
          stroke: transparent;
        }
      }

      .innerText.chosen {
        path {
          stroke: var(--textColor);
        }
      }

      &:focus {
        outline: none;
      }
    }

    .subtitle-item.active,
    .audio-item.active {
      display: flex;

      path {
        stroke: var(--primary);
      }

      &:focus {
        outline: none;
        background-color: var(--neutral);
        color: var(--primary);

        path {
          stroke: transparent;
        }

        .chosen {
          path {
            stroke: var(--primary);
          }
        }
      }
    }
  }
}
