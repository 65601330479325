@import '@styles/colors.scss';
@import '@styles/variables.scss';
@import '@styles/fonts.scss';

.closed-side-menu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  transform: translateX(-182px);
  height: 100%;
  width: 300px;
  background: $closedSideMenuBackground;

  &-items {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 120px;
    height: 100%;
  }

  .closed-side-menu-profile-image-container {
    margin: 46px 0 220px 0;
    text-decoration: none;

    .closed-side-menu-profile-image {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 58px;
      height: 58px;
      background-image: $profileImgBackground;
      opacity: 0.7;
      border-radius: 6px;

      img {
        border-radius: 6px;
      }

      &-letter {
        font-size: $font-size-l;
        font-weight: $font-weight-bold;
        color: $profileImgLetter;
      }
    }

    &.active {
      .closed-side-menu-profile-image {
        opacity: 1;
        border: $border-width-medium solid var(--neutral);
        border-radius: 8px;
      }
    }
  }

  .closed-side-menu-icons-container {
    display: flex;
    flex-direction: column;

    a {
      height: 42px;
      z-index: -1;
      margin-bottom: 42px;

      svg {
        width: 32px;
        height: 32px;
        opacity: 0.4;

        path {
          stroke: var(--textColor);
        }

        .fill {
          fill: var(--textColor);
        }
      }

      .side-menu-border {
        display: none;
      }

      &.active {
        position: relative;

        svg {
          opacity: 1;
        }

        .side-menu-border {
          width: 100%;
          position: absolute;
          bottom: -8px;
          display: block;
          margin-top: 8px;
          border-bottom: 4px solid var(--info);
          border-radius: 15px;
        }
      }

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  &:hover {
    background: linear-gradient(
      90deg,
      var(--secondary) 0%,
      rgba(var(--primary-rgb), 0.8814119397759104) 45%,
      rgba(var(--secondary-rgb), 0.5228685224089635) 76%,
      rgba(var(--secondary-rgb), 0.07749037114845936) 100%
    );
  }
}
