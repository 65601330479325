@import '@styles/colors.scss';
@import '@styles/variables.scss';
@import '@styles/fonts.scss';

.player-controls {
  &-container {
    display: flex;
    padding: 0 120px 0 148px;
    position: absolute;
    bottom: 60px;
    width: 100%;
    height: 100px;
  }

  &-bottom-section {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
  }

  &-button {
    width: 95px;
  }
}

.gradient-overlay {
  background: $playerControlsGradientOverlay;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
