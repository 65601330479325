.hero-skeleton-wrapper {
  position: relative;
  width: 100%;
  height: 840px;

  .hero-skeleton-elements-wrapper {
    position: absolute;
    top: 410px;

    .skeleton-element {
      border-radius: 48px;
    }

    .hero-skeleton-image-title {
      width: 437px;
      height: 50px;
    }

    .hero-skeleton-text {
      width: 437px;
      height: 30px;
      margin-top: 32px;
    }

    .hero-skeleton-button-container {
      display: flex;
      align-items: center;
      margin-top: 40px;

      .hero-skeleton-button-large {
        width: 170px;
        height: 48px;
      }

      .hero-skeleton-button-small {
        width: 50px;
        height: 50px;
        margin-left: 6px;

        &:not(:first) {
          margin-left: 16px;
        }
      }
    }
  }
}
