@import '@styles/colors.scss';
@import '@styles/variables.scss';
@import '@styles/fonts.scss';

.player-wrapper {
  height: 100vh;
  width: 100vw;

  .bmpui-ui-uicontainer.bmpui-controls-shown .bmpui-ui-hugeplaybacktogglebutton:focus {
    box-shadow: none !important;
  }

  .bmpui-ui-subtitle-label {
    font-size: 50px !important;
    bottom: 30px !important;
  }

  .bmpui-ui-buffering-overlay .bmpui-ui-buffering-overlay-indicator:first-of-type {
    -webkit-animation: none;
    animation: none;
    background: url(../../assets/Velvet-Spinner.gif) no-repeat 50%;
    height: 200px;
    width: 200px;
  }

  .bmpui-ui-buffering-overlay-indicator:nth-of-type(2),
  .bmpui-ui-buffering-overlay-indicator:nth-of-type(3) {
    display: none;
  }

  //SEEKBAR STYLE
  .bmpui-ui-controlbar {
    .bmpui-ui-seekbar {
      .bmpui-seekbar {
        .bmpui-seekbar-playbackposition {
          background-color: var(--info);
          height: 14px;
        }

        .bmpui-seekbar-bufferlevel {
          background-color: transparent;
          height: 14px;
        }

        .bmpui-seekbar-playbackposition-marker {
          width: 14px;
          height: 14px;
          background: var(--neutral);
          box-shadow: 0px 0px 4px $boxShadow;
          border: 0.1875em solid var(--neutral);
          left: -6px;
        }

        .bmpui-seekbar-backdrop,
        .bmpui-seekbar-markers,
        .bmpui-seekbar-seekposition {
          height: 14px;
        }
      }

      .bmpui-ui-seekbar-label {
        .bmpui-seekbar-label-inner {
          border-bottom: none;

          > .bmpui-container-wrapper {
            .bmpui-seekbar-label-metadata {
              background: var(--neutral);
              border-radius: $border-radius-bitmovinPlayer;
              color: var(--primary);
              font-weight: $font-weight-bold;

              .bmpui-seekbar-label-title {
                display: none;
              }
            }

            .bmpui-seekbar-thumbnail {
              width: 193px;
            }
          }
        }

        &.bmpui-seekbar-label-inner:after {
          border: none;
        }
      }
    }
  }

  // SUBTITLES STYLE
  .bmpui-ui-subtitlesettingstogglebutton,
  .bmpui-ui-subtitlesettingstogglebutton.bmpui-on {
    background-image: url('../../assets/SUBTITLES-ICON.svg');
  }

  .bmpui-ui-settings-panel {
    background: var(--primary);

    .bmpui-ui-listbox-button {
      margin-bottom: 10px;
      border: $border-width-medium solid transparent;
      font-weight: $font-weight-regular;
      font-size: $font-size-m;
      box-shadow: none;
      filter: none;

      &.bmpui-ui-listbox-button:focus {
        box-shadow: none;
        border: $border-width-medium solid var(--neutral) !important;
        border-radius: $border-radius-bitmovinPlayer;
      }

      &.bmpui-selected {
        background-color: var(--neutral);
        border-radius: $border-radius-bitmovinPlayer;
        font-weight: $font-weight-bold;

        .bmpui-label {
          color: var(--info);
          outline: none;
        }
      }
    }
  }

  //PLAYBACK BUTTON STYLE
  .bmpui-ui-hugeplaybacktogglebutton .bmpui-image {
    background-image: url('../../assets/PLAYBACK-TOOGLE-BUTTON.svg');
  }

  .bmpui-ui-skin-tv .bmpui-ui-hugeplaybacktogglebutton > .bmpui-image {
    background-size: 10vh;
  }

  //Removing the blue box-shadow on the seekbar
  .bmpui-container-wrapper {
    .bmpui-ui-seekbar.bmpui-focus-visible {
      outline-color: none !important;
      border-color: none !important;
    }

    .bmpui-ui-seekbar:focus {
      box-shadow: none !important;
    }

    .bmpui-ui-seekbar:focus .bmpui-seekbar .bmpui-seekbar-backdrop {
      background-color: rgba(var(--textColor-rgb), 0.45);
    }
  }
}

.disabled-pointer-events {
  pointer-events: none;
}

video::cue {
  font-size: $font-size-xl;
  color: var(--textColor);
}
