@import '@styles/colors.scss';
@import '@styles/variables.scss';
@import '@styles/fonts.scss';

.search-box {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 64px;

  &:focus {
    outline: none;
    border: $border-width-medium solid rgba(var(--textColor-rgb), 0.6);
  }

  svg {
    path {
      stroke: var(--textColor);
    }
  }

  &-input {
    outline: none;
    width: 100%;
    height: 75px;
    background: rgba(var(--textColor-rgb), 0.1);
    border: none;
    border-radius: 5px;
    font-size: $font-size-m;
    font-weight: $font-weight-bold;
    line-height: 34px;
    color: var(--textColor);
    padding: 20px 80px 20px 29px;

    &:focus,
    &:focus:hover {
      border: $border-width-thick solid rgba(var(--textColor-rgb), 0.6);
    }

    &:hover {
      border: $border-width-base solid rgba(var(--textColor-rgb), 0.4);
    }
  }

  &-icon {
    position: absolute;
    color: var(--textColor);
    right: 29px;
  }
}
