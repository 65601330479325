@import '@styles/colors.scss';
@import '@styles/variables.scss';
@import '@styles/fonts.scss';

.showPassword {
  display: flex;
  align-items: center;

  label {
    color: rgba(var(--textColor-rgb), 0.7);
    font-weight: $font-weight-medium;
  }

  input[type='checkbox'] {
    display: none;
  }

  &__checkbox {
    margin-right: 10px;
    width: 32px;
    height: 32px;
    background-color: transparent;
    border: $border-width-base solid var(--textColor);
    border-radius: 3px;

    svg {
      display: none;
    }

    &:hover {
      background-color: rgba(var(--textColor-rgb), 0.15);
    }
  }

  &__checkbox__checked {
    margin-right: 10px;
    width: 32px;
    height: 32px;
    background-color: rgba(var(--textColor-rgb), 0.7);
    border: $border-width-base solid var(--textColor);
    border-radius: 3px;

    svg {
      position: absolute;
      top: -13px;
      left: -13px;

      path {
        stroke: var(--primary);
      }
    }
  }

  &:focus {
    outline: none;

    &__checkbox__checked {
      background: rgba(var(--textColor-rgb), 0.9);
    }

    #forgotPassword {
      border: $border-width-medium solid var(--textColor);
    }

    label {
      color: var(--textColor);
    }
  }
}
