@import '@styles/colors.scss';
@import '@styles/variables.scss';
@import '@styles/fonts.scss';
@import '@styles/mixins.scss';

.ht-type {
  .card-image-wrapper {
    background-color: var(--secondary);
    width: 320px;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  p {
    margin-top: 10px;
    font-size: $font-size-s;
    color: var(--textColor);
    @include clamp-text(2);
  }
}
