.tabs-skeleton-container {
  margin-top: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .tab-skeleton {
    width: 202px;
    height: 35px;
    border-radius: 23px;
    margin-right: 27px;
  }
}
