@import '@styles/colors.scss';
@import '@styles/fonts.scss';

.forgot-password {
  font-family: var(--font-family-sans);
  color: var(--textColor);
  display: flex;
  flex-direction: column;
  align-items: center;

  .login-input {
    margin-top: 122px;
    margin-bottom: 60px;

    &__sublabel {
      max-width: 546px;
      text-align: center;

      span {
        font-weight: $font-weight-bold;
      }
    }
  }

  &_instructions {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 550px;

    button {
      margin-top: 60px;
    }
  }

  &_buttons {
    width: 650px;
    display: flex;
    justify-content: center;

    button:not(:last-child) {
      margin-right: 10px;
    }
  }

  .header {
    width: 100%;
  }
}
