@import '@styles/colors.scss';
@import '@styles/fonts.scss';

.app-lang-info-container {
  color: var(--textColor);
  margin: 196px 0 0 56px;

  .profile-title {
    margin-left: 20px;
  }

  .app-lang {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
