.details-skeleton-wrapper {
  width: 1920px;
  height: 1080px;
  padding-left: 120px;

  .hero-skeleton-wrapper {
    height: 736px;

    .hero-skeleton-elements-wrapper {
      top: 300px;

      .hero-skeleton-image-title {
        width: 320px;
        height: 50px;
      }

      .hero-skeleton-text {
        width: 320px;
        height: 30px;
        margin-top: 42px;
      }

      .hero-skeleton-button-container {
        margin-top: 45px;

        .hero-skeleton-button-large,
        .hero-skeleton-button-small {
          margin-top: 35px;
        }

        .hero-skeleton-button-small {
          width: 48px;
          height: 48px;
          margin-left: 11px;
        }
      }
    }
  }
}

.details-skeleton-wrapper.series-skeleton {
  .tab-skeleton {
    height: 45px;
  }

  .rail-skeleton-wrapper {
    margin-top: 84px;

    .card-skeleton-wrapper {
      margin-right: 66px;

      .card-skeleton-image {
        &.movie {
          width: 300px;
        }
      }
    }
  }
}
