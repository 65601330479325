@import '@styles/colors.scss';

.page-not-found {
  .page-not-found-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--textColor);
  }
}
