@import '@styles/variables.scss';

.card-skeleton-wrapper {
  flex-grow: 0;
  flex-shrink: 0;

  .card-skeleton-image {
    &.category {
      width: $categoryCardWidth;
      height: $categoryCardHeight;
      border-radius: $border-radius-base;
    }

    &.movie {
      width: 320px;
      border-radius: $border-radius-base;

      &.horizontal {
        height: 185px;
      }

      &.vertical {
        height: 402px;
      }
    }

    &.poster {
      width: 1680px;
      height: 444px;
      border-radius: 15px;
    }
  }

  .card-skeleton-title {
    width: 247px;
    height: 20px;
    border-radius: 48px;
    margin-top: 18px;
  }

  .card-skeleton-description {
    width: 170px;
    height: 20px;
    border-radius: 48px;
    margin-top: 12px;
  }
}
