@import '@styles/colors.scss';
@import '@styles/fonts.scss';

.wrapper {
  margin: 0 auto;
  color: rgba(var(--textColor-rgb), 0.6);
  padding-top: 330px;
  width: 540px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-right: 120px;

  .empty,
  .addContent {
    text-align: center;
    font-weight: $font-weight-regular;
  }

  p.empty {
    font-size: $font-size-m;
    margin-bottom: 16px;
  }

  p.addContent {
    font-size: $font-size-s;
  }

  .navigation-button__container {
    margin-top: 40px;
  }
}
