@import '@styles/colors.scss';
@import '@styles/fonts.scss';

.search-page {
  width: 1920px;
  min-height: 1080px;
  padding-left: 125px;
  padding-top: 115px;
  display: flex;
  justify-content: space-between;

  p {
    color: rgba(var(--textColor-rgb), 0.6);
    font-size: $font-size-m;
    line-height: 34px;
    font-weight: $font-weight-bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-no-results {
    margin-bottom: 80px;

    .second-line p {
      font-weight: $font-weight-regular;
    }
  }

  .left-part-suggestions {
    width: 30%;
    margin-left: 58px;
    display: flex;
    flex-direction: column;

    .inner-container {
      width: 546px;
      position: fixed;
      top: 117px;
      left: 185px;

      .results {
        width: 100%;
        max-height: 432px;
        overflow: hidden;
        margin-top: 13px;
        background-color: var(--secondary);

        .suggestion {
          height: 72px;
          display: flex;
          align-items: center;
          padding: 0 0 0 16px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          margin-right: 0;

          &:hover {
            background-color: rgba(var(--textColor-rgb), 0.1);

            p {
              color: var(--textColor);
            }
          }
        }

        .suggestion:focus,
        .suggestion:focus:hover {
          outline: none;
          background-color: var(--textColor);

          p {
            color: var(--secondary);
          }
        }

        &::-webkit-scrollbar {
          width: 23px;
        }

        &::-webkit-scrollbar-thumb {
          border: 9px solid transparent;
          background-clip: padding-box;
          background-color: rgba(var(--textColor-rgb), 0.6);
          border-radius: 14px;
        }
      }
    }
  }

  .right-part-results {
    padding-left: 40px;
    width: 65%;
    display: flex;
    flex-direction: column;

    .search-text {
      padding-right: 100px;
      display: flex;
      align-items: flex-start;
      height: 70px;

      &-no-results {
        margin-bottom: 80px;

        .second-line p {
          font-weight: $font-weight-bold;
        }
      }

      .search-title {
        height: 64px;
        display: flex;
        align-items: center;
      }
    }

    .search-no-result {
      color: var(--textColor);
      margin-bottom: 30px;
    }

    .search-content {
      display: flex;
      flex-direction: column;
      position: relative;
      margin: 21px 0 5px 0;

      .card-wrapper {
        display: flex;
        flex-wrap: wrap;

        .card-container {
          margin: 0 32px 90px 0;

          .ht-type {
            width: 320px;
            height: 185px;
          }
        }
      }
    }
  }
}
