@import '@styles/colors.scss';

.initial-screen {
  height: 1080px;
  width: 1920px;
  background: linear-gradient(90deg, rgba(var(--primary-rgb), 0.95) 3%, rgba(var(--primary-rgb), 0.2) 41%),
    linear-gradient(360deg, rgba(var(--primary-rgb), 0.95) 2.04%, rgba(var(--primary-rgb), 0) 41.11%);

  svg {
    width: 237px;
    height: 60px;
    position: absolute;
    top: 92px;
    left: 107px;
  }

  .background-img {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
  }

  .initial-screen-content {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
