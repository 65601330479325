@import '@styles/colors.scss';
@import '@styles/fonts.scss';

.step {
  width: 450px;
  font-weight: $font-weight-medium;

  &__text {
    font-size: $font-size-l;
    color: rgba(var(--textColor-rgb), 0.6);
    margin-top: 24px;
  }
}
