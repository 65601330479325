@import '@styles/colors.scss';
@import '@styles/variables.scss';
@import '@styles/fonts.scss';

.secondary-player-button {
  color: var(--textColor);
  background-color: rgba(var(--neutral-rgb), 0.3);
  font-size: $font-size-m;
  font-weight: $font-weight-medium;
  display: flex;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 41px;
  border: none;
  outline: none;

  svg {
    margin-right: 8px;

    path {
      stroke: var(--textColor);
    }
  }

  &:focus,
  &:hover {
    background-color: var(--neutral);
    color: var(--primary);

    path {
      stroke: var(--primary);
    }
  }
}
