// Rails
$railCardSpacing: 20px;

// Categories
$categoryCardWidth: 263px;
$categoryCardHeight: 100px;

// Border settings
$border-width-base: 1px;
$border-width-medium: 2px;
$border-width-thick: 4px;

$border-radius-base: 10px;
$border-radius-circle: 50%;
$border-radius-bitmovinPlayer: 70px;

