@import '@styles/colors.scss';
@import '@styles/fonts.scss';

.category-page {
  padding-left: 142px;

  .category-title {
    text-transform: capitalize;
    color: var(--textColor);
    font-weight: $font-weight-bold;
    font-size: $font-size-m;
    line-height: 34px;
    margin: 92px 0 22px 0;
  }

  .card-wrapper {
    display: flex;
    flex-wrap: wrap;

    > div {
      width: 320px;
      margin: 0 20px 40px 0;
    }
  }
}
