// Black varieties:
$standardBlack: #000000;
$velvetGrayOpacity: rgba(0, 0, 0, 0.5);
$boxShadow: rgba(0, 0, 0, 0.25);
$playerControlsGradientOverlay: linear-gradient(
  180deg,
  rgba(0, 0, 0, 0.9) 2.04%,
  rgba(31, 31, 31, 0.87) 14.02%,
  rgba(62, 62, 62, 0.3) 46.88%,
  rgba(4, 4, 4, 0.84) 80.45%,
  rgba(0, 0, 0, 0.88) 100%
);
$playerModalBackground: rgba(0, 0, 0, 0.85);

// Profile picture colors
$profileImgLetter: #ae0092;
$profileImgBackground: linear-gradient(to bottom right, #d9d9d9, #ffc9f0);

// Linear-gradient colors
$actorImgBackground: linear-gradient(180deg, rgba(var(--neutral-rgb), 0) 68.84%, rgba(var(--neutral-rgb), 0.6) 90.41%);
$heroBackground: linear-gradient(90deg, var(--primary) 34.96%, transparent 50%),
  linear-gradient(360deg, var(--primary) 1.04%, transparent 21.11%);
$movieDetailBackground: linear-gradient(90deg, var(--primary) 36%, transparent 62.11%),
  linear-gradient(360deg, var(--primary) 6.04%, transparent 41.11%);

$legalTextBackground: linear-gradient(
  180deg,
  rgba(var(--bgGradientTo), 0.458420868347339) 0%,
  rgba(var(--bgGradientTo), 1) 35%,
  rgba(var(--bgGradientTo), 1) 100%
);
$legalTextBackground: linear-gradient(180deg, rgba(var(--secondary-rgb), 0) 0%, var(--bgGradientTo) 59.34%);
$legalTextBackgroundFocus: linear-gradient(180deg, rgba(var(--secondary-rgb), 0) -31.11%, var(--secondary) 67.43%);

// Menu colors
$openSideMenuBackground: linear-gradient(
  90deg,
  var(--primary) 13.27%,
  rgba(var(--primary-rgb), 0.616952) 21.71%,
  rgba(var(--primary-rgb), 0.5) 100%
);
$closedSideMenuBackground: linear-gradient(
  90deg,
  var(--primary) 0%,
  rgba(var(--primary-rgb), 0.8814119397759104) 45%,
  rgba(var(--primary-rgb), 0.5228685224089635) 76%,
  rgba(var(--primary-rgb), 0.07749037114845936) 100%
);

// On hold until Rent&Buy is active again
$detailsPageButton: linear-gradient(102.22deg, rgba(255, 255, 255, 0.35) 52.93%, rgba(204, 204, 204, 0.35) 120.48%);
$detailsPageButtonFocusBackground: linear-gradient(102.22deg, #ffffff 52.93%, #cccccc 120.48%);

// On hold until SportsHero is active again
$sportsCountdownBackground: linear-gradient(170.65deg, #f3f3f3 26.99%, #cbcbcb 93.28%);
$sportsCounterBackground: linear-gradient(92.77deg, rgba(255, 255, 255, 0.6) 10.17%, rgba(228, 228, 228, 0.6) 100%);
$sportsCardBackground: linear-gradient(92.77deg, rgba(255, 255, 255, 0.3) 10.17%, rgba(228, 228, 228, 0.3) 100%);
