.my-list-skeleton-wrapper {
  .my-list-skeleton-page-title {
    width: 150px;
    height: 34px;
    border-radius: 48px;
    margin: 92px 0 22px 0;
  }

  .my-list-skeleton-content {
    display: flex;
    flex-wrap: wrap;

    .card-skeleton-wrapper {
      margin: 0 20px 40px 0;
    }
  }
}
