@import '@styles/colors.scss';

.logout-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--textColor);
  margin-top: 388px;

  .secondary-button {
    width: 455px;

    &:not(:first-of-type) {
      margin-top: 25px;
    }
  }
}
