@import '@styles/colors.scss';
@import '@styles/fonts.scss';

.header {
  padding: 61px 110px 0 110px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 50px;
  font-weight: $font-weight-regular;
  color: var(--textColor);
  line-height: 65px;
}
