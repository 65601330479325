@import '@styles/colors.scss';
@import '@styles/variables.scss';
@import '@styles/fonts.scss';
@import '@styles/mixins.scss';

.hero {
  background: $heroBackground;
  height: 820px;

  &-background-image {
    position: relative;
    height: 1px;
  }

  .image-wrapper.hero {
    background: none;
    top: 0;
    right: 0;
    z-index: -1;
    height: 820px;

    img {
      height: 820px;
    }
  }

  .hero-info {
    display: flex;
    flex-direction: column;
    padding: 140px 0 0 120px;
    max-width: 900px;
    color: var(--textColor);

    .hero-image-title {
      width: 50%;
      height: 176px;
      margin-top: 50px;
    }

    .hero-title {
      font-size: 64px;
      margin-bottom: 20px;
      margin-top: 20px;
    }

    .hero-details {
      display: flex;
      align-items: center;
      font-size: $font-size-m;
      font-weight: $font-weight-regular;
      margin-top: 45px;
      margin-bottom: 15px;

      > div:not(:last-child) {
        margin-right: 30px;
      }

      .hero-matchscore {
        color: var(--success);
        width: 210px;
      }

      .match {
        font-weight: $font-weight-medium;
        font-size: $font-size-m;
        color: var(--info);
        margin-right: 20px;
        max-width: 152px;
      }

      .ageRating16,
      .ageRating12,
      .ageRating7,
      .ageRating0 {
        box-shadow: 0px 0px 10px rgba(var(--textColor-rgb), 0.15);
        border-radius: 4px;
        line-height: 24px;
        padding: 6px;
        margin-right: 20px;
      }

      .ageRating16,
      .ageRating0 {
        color: var(--textColor);
      }

      .ageRating12,
      .ageRating7 {
        color: var(--primary);
      }

      .ageRating0 {
        background: var(--success);
      }

      .ageRating7 {
        background: var(--info);
      }

      .ageRating12 {
        background: var(--warning);
      }

      .ageRating16 {
        background: var(--danger);
      }

      .hero-genre {
        max-width: 273px;
        margin-right: 20px;
      }

      .hero-genre,
      .subtitle {
        font-weight: $font-weight-bold;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        font-size: $font-size-m;
      }

      .subtitle {
        max-width: 245px;
      }
    }

    .hero-description {
      color: var(--textColor);
      font-size: $font-size-m;
      font-weight: $font-weight-regular;
      max-width: 800px;
      @include clamp-text(2);
    }

    .hero-buttons {
      display: flex;
      align-items: center;
      margin-top: 40px;

      .cta-button {
        .cta-button-icon {
          svg {
            path {
              stroke: var(--textColor);
              fill: var(--textColor);
            }
          }
        }

        &:focus,
        &:focus:hover {
          font-weight: $font-weight-bold;
          transform: scale(1.02);
          transition: transform 0.4s ease-in-out;
          border: $border-width-base solid var(--neutral);
          background-color: var(--neutral);
          color: var(--primary);

          .cta-button-icon {
            svg {
              path {
                stroke: var(--primary);
                fill: var(--primary);
              }
            }
          }
        }

        &:hover {
          transition: transform 0.4s ease-in-out;
          background-color: rgba(var(--textColor-rgb), 0.1);
        }
      }

      .action-buttons {
        display: flex;

        &.en {
          left: 175px;
        }

        &.es {
          left: 260px;
        }

        .action-button {
          position: relative;

          .action-button-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            svg {
              path {
                stroke: var(--textColor);
              }

              circle {
                fill: var(--textColor);
              }
            }
          }

          &:focus,
          &:focus:hover {
            font-weight: $font-weight-bold;
            transform: scale(1.06);
            transition: transform 0.6s ease-in-out;
            background-color: var(--neutral);
            color: var(--primary);

            .fill,
            .plus {
              stroke: var(--primary);
            }

            circle.fill {
              fill: var(--primary);
            }
          }

          &:hover {
            transition: transform 0.6s ease-in-out;
            background-color: rgba(var(--neutral-rgb), 0.1);
            border-color: rgba(var(--neutral-rgb), 0.6);
          }
        }
      }

      .focusable {
        outline: none;

        &:focus {
          outline: none;
        }
      }

      .hero-plus-button {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 50px;
        height: 50px;
        border-radius: $border-radius-circle;
        border: 3px solid var(--neutral);
        background: transparent;
      }
    }
  }

  .hero-image {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    height: 820px;
  }
}
