@import '@styles/colors.scss';
@import '@styles/variables.scss';
@import '@styles/fonts.scss';

.tag {
  &.card {
    background-color: rgba(var(--textColor-rgb), 0.6);
    width: fit-content;
    font-size: $font-size-s;
    font-weight: $font-weight-regular;
  }

  &.step {
    width: fit-content;
    border: $border-width-thick solid rgba(var(--textColor-rgb), 0.6);
    border-radius: 68px;
    font-size: $font-size-l;
    font-weight: $font-weight-medium;
    color: var(--textColor);
    line-height: 39px;
    padding: 4px 20px;
  }
}
