@import '@styles/colors.scss';
@import '@styles/variables.scss';
@import '@styles/fonts.scss';

.cta-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: $border-width-base solid transparent;
  cursor: pointer;
  color: var(--textColor);
  height: 48px;
  width: auto;
  font-weight: $font-weight-regular;
  font-size: $font-size-m;
  padding: 0 32px;
  margin-right: 16px;
  border-radius: 40px;
  background-color: rgba(var(--neutral-rgb), 0.35);

  .cta-button-icon {
    display: flex;
    align-items: center;

    svg {
      width: 25px;
      height: 25px;
    }
  }

  .cta-button-text {
    white-space: nowrap;
    margin-left: 18px;
  }
}
