@import '@styles/colors.scss';
@import '@styles/variables.scss';
@import '@styles/fonts.scss';
@import '@styles/mixins.scss';

.card-container {
  max-width: 385px;
  margin: 15px 0;
  outline: none;

  img {
    border-radius: $border-radius-base;
  }

  .hs-type,
  .vs-type,
  .hc-type,
  .hcd-type,
  .hcw-type,
  .ht-type,
  .hcat-type {
    position: relative;
    opacity: 0.8;

    &__focus {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: $border-radius-base;
      border: none;
      z-index: 2;
    }

    &__focused {
      border: $border-width-thick solid var(--textColor);
      transform: scale(1.09);
    }

    &__full_opacity {
      opacity: 1;
    }

    &__outside {
      opacity: 0.2;
    }

    &__no-focus {
      width: 100%;
      height: 100%;
    }

    .row-card-image-focused {
      transform: scale(1.09);
      opacity: 1;
    }
  }

  .card-image-wrapper {
    width: 320px;
    height: 185px;
    border-radius: $border-radius-base;

    &.vertical {
      width: 320px;
      height: 402px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &:focus,
  &:focus:hover {
    .hs-type,
    .vs-type,
    .ht-type {
      .card-image-wrapper {
        border: $border-width-thick solid var(--textColor);
        transform: scale(1.09);
        border-radius: 13.5px;
        opacity: 1;
      }
    }
  }

  .ec-type.active {
    opacity: 1;

    .image-wrapper {
      transform: scale(1.09);
      width: 100%;
      height: 100%;
      border: $border-width-thick solid var(--textColor);
      border-radius: 13.5px;
    }

    .ec-type-top {
      .episode-season {
        z-index: 11;
        display: block;
      }

      .episode-video-bar {
        position: absolute;
        top: 160px;
        left: 2px;
        height: 4px;
        width: 98%;
        z-index: 3;
        border-radius: $border-radius-base;
        background-color: rgba(var(--textColor-rgb), 0.5);

        .episode-video-bar-progress {
          height: 4px;
          width: 100%;
          border-radius: $border-radius-base;
          background-color: var(--info);
        }
      }
    }

    .ec-type-bottom {
      .episode-description {
        opacity: 1;
      }
    }
  }
}

.card-container {
  &:hover {
    .hs-type,
    .hcw-type__container,
    .vs-type,
    .hc-type,
    .hcd-type,
    .ht-type {
      .card-image-wrapper {
        opacity: 0.6;
        transition: 0.2s all ease-out;
      }
    }

    .ec-type {
      img {
        opacity: 0.6;
        transition: 0.2s all ease-out;
      }
    }

    .hs-type__full_opacity,
    .vs-type__full_opacity,
    .hc-type__full_opacity,
    .hcd-type__full_opacity {
      .row-card-image-focused {
        opacity: 1;
      }
    }

    .hcw-type__container.hcw-type__focused {
      .card-image-wrapper {
        opacity: 1;
      }
    }

    .ec-type.active {
      img {
        opacity: 1;
      }
    }

    .hcat-type {
      color: rgba(var(--textColor-rgb), 0.6);
      transition: 0.2s all ease-out;
    }
  }
}
