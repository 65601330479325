@import '@styles/colors.scss';
@import '@styles/fonts.scss';

.initial-screen-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 77px 107px;

  .text {
    color: var(--textColor);
    font-weight: $font-weight-medium;

    p:first-child {
      font-size: $font-size-xxl;
      margin-bottom: 12px;
    }

    p:last-child {
      font-size: $font-size-xl;
    }
  }

  .buttons {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    .secondary-button {
      min-width: 320px;
      height: 54px;
      padding: 0;
      font-weight: $font-weight-regular;

      &:focus {
        background: var(--textColor);
        color: var(--primary);
        font-weight: $font-weight-medium;
      }
    }

    .secondary-button:first-of-type {
      margin-right: 40px;
    }
  }
}
