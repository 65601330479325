@import '@styles/colors.scss';
@import '@styles/variables.scss';
@import '@styles/fonts.scss';

.action-button {
  width: fit-content;
  height: 46px;
  width: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius-circle;
  font-weight: $font-weight-regular;
  font-size: $font-size-xl;
  cursor: pointer;
  color: var(--textColor);
  border: $border-width-medium solid var(--neutral);
  background-color: rgba(var(--neutral-rgb), 0.35);
  background: transparent;
  transition: transform 0.4s ease-in-out;
  margin-right: 16px;

  .action-button-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: $font-weight-regular;

    svg path {
      width: 22px;
      height: 22px;
    }
  }
}

.action-button.active {
  transform: scale(1.04);
}
