@import '@styles/colors.scss';

.image-wrapper {
  width: 100%;
  height: 100%;
  border-radius: inherit;

  &.hero {
    background-color: var(--secondary);

    img {
      height: 735px;
    }
  }

  &.title {
    background: transparent;
  }

  &.poster {
    background-color: var(--secondary);
  }

  .hidden {
    opacity: 0;
  }

  .visible {
    opacity: 1;
  }
}
