@import '@styles/colors.scss';
@import '@styles/fonts.scss';

.title {
  &.page {
    font-size: $font-size-xxl;
    font-weight: $font-weight-bold;
    color: var(--textColor);
  }
}
