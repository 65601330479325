@import '@styles/colors.scss';
@import '@styles/variables.scss';
@import '@styles/fonts.scss';

.hspc-type {
  opacity: 0.8;
  height: 100%;
  background-color: var(--secondary);
  border-radius: $border-radius-base;

  &__focus {
    border-radius: $border-radius-base;
    opacity: 1;
    box-shadow: 0 0 0 $border-width-thick var(--textColor);
  }

  .card-image-wrapper {
    width: 100%;
    height: 454px;
  }

  &:hover {
    opacity: 0.6;
    transition: 0.2s all ease-out;
  }
}
