@import '@styles/colors.scss';
@import '@styles/fonts.scss';

.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--textColor);
  margin-top: 196px;

  svg {
    stroke: var(--textColor);

    path {
      fill: var(--textColor);
    }
  }

  .release-version {
    font-size: $font-size-m;
    font-weight: $font-weight-medium;
    color: rgba(var(--textColor-rgb), 0.6);
    position: absolute;
    bottom: 70px;
  }
}
