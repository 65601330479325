.skeleton-wrapper {
  height: 100vh;
  overflow: hidden;

  .skeleton-element {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.1) 25%,
      rgba(255, 255, 255, 0.05) 50%,
      rgba(255, 255, 255, 0.1) 75%
    );
    background-size: 200% 100%;
    animation: skeleton-loading-animation 2s infinite linear;

    @keyframes skeleton-loading-animation {
      0% {
        background-position: 100% 0;
      }
      100% {
        background-position: -100% 0;
      }
    }
  }
}
