@import '@styles/colors.scss';
@import '@styles/variables.scss';
@import '@styles/fonts.scss';

.splide-custom-movies {
  outline: none;
  margin-left: 120px;
  margin-top: 80px;

  .card-container {
    max-width: 98%;
    width: 100%;
    height: 454px;
  }

  .splide {
    .is-active {
      &:hover {
        img {
          opacity: 1;
        }
      }
    }
    &__arrows {
      display: none;
    }

    &__track {
      margin-bottom: 80px;

      .splide__slide {
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: $railCardSpacing;
        overflow: hidden;
        padding-bottom: 5px;

        img {
          height: 100%;
          object-fit: cover;
          border-radius: $border-radius-base;
        }
      }
    }

    &__list {
      width: 1684px;
    }

    &__pagination {
      width: 90%;
      left: auto;
      right: auto;
      padding-left: 85px;
      bottom: 30px;

      li .splide__pagination__page {
        margin: 0 10px;
        background-color: rgba(var(--textColor-rgb), 0.6);
        width: 12px;
        height: 12px;

        &.is-active {
          background-color: var(--textColor);
          outline: none;
        }
      }
    }
  }

  .slide {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: $border-radius-base;
    display: flex;
    align-items: flex-end;

    .wrapper {
      display: flex;
      flex-direction: column;
      margin: 0 0 18px 76px;

      h3 {
        color: var(--textColor);
        font-weight: $font-weight-bold;
        font-size: $font-size-m;
        line-height: 34px;
        margin-left: 13px;
      }
    }
  }
}
