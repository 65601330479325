@import '@styles/colors.scss';
@import '@styles/fonts.scss';

.legal-container {
  display: flex;
  color: var(--textColor);

  .section-left {
    margin: 196px 0 0 56px;
  }

  .section-right {
    margin: 0 188px 0 152px;

    .legal-content-text-item {
      width: 1087px;
      height: 1080px;
      overflow-y: scroll;
      padding: 92px 37px 92px 20px;

      &::after {
        content: '';
        position: absolute;
        left: 644px;
        right: 210px;
        height: 90px;
        bottom: 0px;
        background: $legalTextBackground;
      }

      &:focus {
        outline: none;
        background: var(--secondary);

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 644px;
          right: 210px;
          height: 90px;
          background: $legalTextBackgroundFocus;
          transform: rotate(-180deg);
        }

        &::after {
          content: '';
          position: absolute;
          left: 644px;
          right: 210px;
          height: 90px;
          bottom: 0px;
          background: $legalTextBackgroundFocus;
        }
      }

      &::-webkit-scrollbar {
        width: 23px;
      }

      &::-webkit-scrollbar-thumb {
        border: 9px solid transparent;
        background-clip: padding-box;
        background-color: rgba(var(--textColor-rgb), 0.6);
        border-radius: 14px;
      }

      .legal-content-text-template {
        width: 100%;

        .legal-content-text-template-title {
          font-size: $font-size-l;
          font-weight: $font-weight-bold;
          margin-bottom: 36px;
        }

        .legal-content-text-template-description {
          font-size: $font-size-m;
          line-height: 34px;

          .policy-introduction {
            margin-bottom: 10px;
          }

          .policy-title {
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}
