@import '@styles/colors.scss';
@import '@styles/variables.scss';
@import '@styles/fonts.scss';

.login-input {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__label {
    font-size: $font-size-xxl;
    font-weight: $font-weight-bold;
    line-height: 52px;
  }

  &__sublabel {
    font-size: $font-size-m;
    font-weight: $font-weight-regular;
    line-height: 34px;
    margin-top: 16px;
  }

  form {
    &.focusable {
      outline: none;
    }
  }

  input {
    background: rgba(var(--textColor-rgb), 0.23);
    width: 660px;
    height: 64px;
    outline: none;
    border: $border-width-base solid rgba(var(--textColor-rgb), 0.6);
    border-radius: 4px;
    font-size: $font-size-m;
    font-weight: $font-weight-regular;
    color: var(--textColor);
    padding: 15px 12px;
    margin-top: 32px;

    &:focus,
    &:focus:hover {
      border: $border-width-thick solid var(--textColor);
    }

    &:hover {
      border: $border-width-medium solid rgba(var(--textColor-rgb), 0.7);
    }
  }

  ::placeholder {
    color: rgba(var(--textColor-rgb), 0.6);
  }

  &__error-border {
    border: $border-width-base solid var(--danger);
  }

  &__error-container {
    max-width: 660px;
    display: flex;
    align-items: flex-start;
    margin-top: 8px;
  }

  &__error-message {
    font-size: $font-size-s;
    color: var(--danger);
    margin-left: 4px;
    width: 100%;
  }

  svg {
    min-width: 21px;
    margin-top: 3px;

    path {
      stroke: var(--danger);
    }
  }
}
