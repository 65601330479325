@import '@styles/colors.scss';
@import '@styles/variables.scss';
@import '@styles/fonts.scss';

.navigation-button {
  padding: 5px 24px;
  font-weight: $font-weight-regular;
  font-size: $font-size-s;
  background: transparent;
  border: $border-width-thick solid rgba(var(--textColor-rgb), 0.2);
  box-shadow: 0px 4px 4px $boxShadow;
  color: rgba(var(--textColor-rgb), 0.6);
  border-radius: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 237px;

  &:focus {
    outline: none;
    background: var(--textColor);
    color: var(--primary);
  }
}
