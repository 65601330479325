@import '@styles/colors.scss';
@import '@styles/fonts.scss';

.register {
  .navigation-button {
    width: fit-content;
    border: none;
    padding: 10px 25px;

    .arrow-left-icon {
      margin-right: 8px;
    }

    span {
      font-size: $font-size-m;
      font-weight: $font-weight-medium;
    }
  }

  &__content-container {
    padding: 59px 120px 0px 120px;
  }

  .title {
    &:not(:first-of-type) {
      margin-top: 61px;
    }
  }

  &__steps-container {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
  }

  &__qr-code-container {
    background-color: var(--textColor);
    width: 408px;
    height: 408px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 29px;
  }

  &__qr-code {
    width: 352px;
    height: 352px;
  }

  &__link {
    font-size: $font-size-m;
    font-weight: $font-weight-bold;
    color: var(--textColor);
    margin-top: 26px;
  }
}
