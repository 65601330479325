@import '@styles/colors.scss';
@import '@styles/fonts.scss';

.lang-button {
  height: 72px;
  width: 490px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: $font-size-m;
  padding: 16px 32px;

  .innerText {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 95%;

    .language {
      margin-left: 60px;
    }
  }

  .innerText.active {
    .language {
      margin-left: 0;
    }

    svg {
      path {
        stroke: var(--textColor);
      }
    }
  }

  &:focus,
  &:focus:hover {
    outline: none;
    background-color: var(--neutral);
    color: var(--primary);

    .innerText.active {
      path {
        stroke: var(--primary);
      }
    }
  }

  &:hover {
    background-color: rgba(var(--secondary-rgb), 0.9);
    color: var(--textColor);
  }
}
