@import '@styles/colors.scss';
@import '@styles/variables.scss';
@import '@styles/fonts.scss';

.rail-section-title {
  font-size: $font-size-m;
  font-weight: $font-weight-bold;
  line-height: 32px;
  letter-spacing: 0em;
  color: rgba(var(--textColor-rgb), 0.8);
  padding-left: 120px;
  margin-bottom: 3px;
  opacity: 0.6;
}

.rail {
  position: relative;
  outline: none;
  margin-bottom: 32px;

  // When on details page and return on home, if the last focused card on home was vertical simple card
  // and that rail is the last one on the home page, it was not scrolling to the bottom of the rail 
  // to focus the whole card, it was showing only part of rail's height. This is the fix 
  min-height: 130px;
  max-height: 457px;

  .box-shadow-splide {
    position: absolute;
    left: 0;
    width: 110px;
    height: 100%;
    z-index: 200;
  }

  .splide {
    &__track {
      padding: 0 120px !important;
      overflow: hidden;
    }

    &__slide {
      margin-right: $railCardSpacing !important;
      border: none !important;

      &.is-active {
        border: none !important;
      }
    }

    &.is-focus-in,
    &__slide:focus-visible,
    &__slide:focus {
      outline: none !important;
      outline-offset: 0;
    }

    &__arrow {
      visibility: hidden;
    }
  }
}
